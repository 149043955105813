#comp-load {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#comp-load span {
  font-size: 14px;
  font-weight: 500;
  margin-top: -80px;
  z-index: 2;
}