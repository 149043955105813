#LayoutSite {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #eef1f6;
}

#LayoutSite .context {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

#LayoutSite nav {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 15px 0px #c0c0c0;
  box-shadow: 0px 0px 15px 0px #c0c0c0;
}

#LayoutSite nav .carrinho {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  background-color: #688edb;
  color: #fff;
  padding: 7px 10px;
  border-radius: 25px;
  cursor: pointer;
  border: none;
}

#LayoutSite nav .logo {
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 15px;
  cursor: pointer;
}

#LayoutSite nav .navLink {
  padding: 10px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 25px 20px;
  text-decoration: none;
  color: #8788b0;
  cursor: pointer;
  transition: all 0.4s;
}

#LayoutSite nav .navLink:hover {
  color: #688edb;
}

#LayoutSite nav .selected {
  background: #688edb;
  color: #fff !important;
}

#LayoutSite .content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 35px 50px;
}

@media (max-width: 767px) {
  #LayoutSite .content {
    padding: 35px 15px;  
  }
}


#LayoutSite .lytFooter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 15px 0px #c0c0c0;
  box-shadow: 0px 0px 15px 0px #c0c0c0;
  gap: 5px;
  padding: 15px;
}

#LayoutSite .lytFooter img {
  height: 60px;
}

#LayoutSite .lytFooter div p {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}