#pgLoginCliente {
  display: flex;
  width: 100%;
}

#pgLoginCliente .sessionLogin {
  width: 100%;
  text-align: center;
  font-size: 16px;
}

#pgLoginCliente .sessionLogin .sessionBtnContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  gap: 10px;
  margin-top: 10px;
}

#pgLoginCliente .sessionLogin .sessionBtnContent button {
  width: 70px;
  padding: 5px;
  font-size: 14px;
}

#pgLoginCliente .painel {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media screen and (max-width: 768px) {
  #pgLoginCliente {
    flex-direction: column;
    align-items: center;
  }

  #pgLoginCliente .painel {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  #pgLoginCliente {
    flex-direction: row;
    align-items: flex-start;
    gap: 15px;
  }

  #pgLoginCliente .painel {
    width: 49%;
  }
}

#pgLoginCliente .painel button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #6dbc25;
  border: none;
  color: #fff;
  padding: 9px;
  border-radius: 25px;
  width: 145px;
  align-self: flex-end;
  cursor: pointer;
}

#pgLoginCliente .painel .newCad {
  width: 200px;
  align-self: center;
  margin-top: 15px;
}