#pgClientes {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#pgClientes .topo {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

#pgClientes .table .head {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  color: #616e79;
  font-size: 16px;
  border-bottom: 1.5px solid #7e868d;
  line-height: 27px;
  border-radius: 7px;
}

#pgClientes .table .head span {
  margin-left: 5px;
}

#pgClientes .table .tableContent {
  width: 100%;
}

#pgClientes .table .tableContent .rowTable {
  margin: 5px 0px;
  padding: 5px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  border-radius: 5px;
}

#pgClientes .table .tableContent .rowTable:hover {
  background-color: #f5f6f9;
}