#pgCursosForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#pgCursosForm .form .title {
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  border-bottom: 1.5px solid #aaa;
  padding: 0px 10px;
  border-radius: 5px;
}

#pgCursosForm .form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

#pgCursosForm .btnPainel {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 0 auto;
}

#pgCursosForm .form label {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}

#pgCursosForm .form>div {
  min-width: 300px;
}

#pgCursosForm .form textarea {
  height: 100px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 4px 16px;
  font-size: 14px;
  background: #e8ebf3;
  color: #333;
  resize: none;
}