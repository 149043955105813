#finallyPgto {
  width: 100%;
  display: flex;
  gap: 15px;
}

#finallyPgto .overlayPgto {
  background: #000;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.85
}

#finallyPgto .painel {
  margin: 0px;
}

#finallyPgto .details {
  align-self: flex-start;
}

#finallyPgto .details div {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

#finallyPgto .details h4 {
  text-align: right;
  margin-top: 15px;
  border-top: 1px solid #ddd;
  padding: 3px 4px;
}

#finallyPgto .cliente {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 10px;
}


#finallyPgto .paymentContent {
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: flex-start;
}

#finallyPgto .paymentContent .methodPay {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 10px;
}

@media screen and (max-width: 550px) {
  #finallyPgto .paymentContent {
    flex-direction: column;
  }

  #finallyPgto .paymentContent .methodPay {
    width: 100%;
  }
}

@media screen and (min-width: 551px) {
  #finallyPgto .paymentContent {
    flex-direction: row;
  }
}

#finallyPgto .paymentContent .methodPay button {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 120px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  color: #959595;
}

#finallyPgto .paymentContent .methodPay button.selected {
  background-color: #688edb;
  color: #FFF;
}

#finallyPgto .formPay {
  flex: 1;
  min-height: 270px;
}

#finallyPgto .methodPay button svg {
  width: 35px;
  height: 35px;
}

#finallyPgto .details div .qtd {
  width: 25px;
}

#finallyPgto .details div .nome {
  flex: 1;
}

#finallyPgto .details div .valor {
  font-weight: 500;
}

@media screen and (min-width: 769px) {
  #finallyPgto {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  #finallyPgto .details {
    width: 60%;
  }

  #finallyPgto .cliente {
    width: 35%;
  }
}

@media screen and (max-width: 768px) {
  #finallyPgto {
    flex-direction: column;
  }
}

#finallyPgto .paymentContent .formPay label {
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
}

#finallyPgto .paymentContent .formPay input {
  margin-bottom: 10px !important;
}

#finallyPgto .paymentContent .formPay .flexRow {
  display: flex;
  gap: 10px;
}

#finallyPgto .paymentContent .formPay .flexRow input {
  max-width: 170px;
}

#finallyPgto a.btn,
#finallyPgto a.btn-boleto,
#finallyPgto .paymentContent .formPay button {
  border: none;
  padding: 8px 25px;
  border-radius: 25px;
  color: #fff;
  background-color: #6dbc25;
  margin: 15px auto;
  cursor: pointer;
  text-decoration: none;
}

a.btn-boleto {
  color: #fff;
  background-color: #6dbc25;
  border: none;
  padding: 8px 25px;
  border-radius: 25px;
  cursor: pointer;
  margin: 5px auto;
  display: inline-block;
  text-decoration: none;
}

#finallyPgto .paymentContent .formPay h3 {
  color: #c3c3c3;
  text-align: center;
  margin-top: 15px;
}