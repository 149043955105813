#pgCursoSite {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 768px) {
  #pgCursoSite {
    flex-direction: column;
  }

  #pgCursoSite .price {
    width: 100%;
  }

  #pgCursoSite .info {
    width: 100%;
  }
}

@media (min-width: 769px) {
  #pgCursoSite {
    flex-direction: row;
  }

  #pgCursoSite .price {
    width: 35%;
  }

  #pgCursoSite .info {
    width: 63%;
  }
}

#pgCursoSite .painel {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px 15px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 15px 0px #dbdbdb;
  box-shadow: 0px 0px 15px 0px #dbdbdb;
}

#pgCursoSite .painel .imgsCarousel .carousel-slider {
  /* min-height: 250px; */
  max-height: calc(100vh * .8);
}

#pgCursoSite .painel .imgsCarousel {
  margin-top: 15px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

#pgCursoSite .painel .imgsCarousel img {
  object-fit: scale-down;
  max-height: calc(100vh * .8);
  border-radius: 5px;
}


/* #pgCursoSite .painel .capa {
  min-height: 200px;
  max-height: calc(100vh * .8);
  margin-top: 15px;
  object-fit: scale-down;
  background-color: #e3e3e3;
} */

#pgCursoSite .painel .capa {
  position: relative;
  margin-top: 15px;
  /* max-width: 700px; */
}
#pgCursoSite .painel .capa img {
  /* position: absolute; */
  /* max-height: calc(100vh * .5); */
  object-fit: contain;
  width: 100%;
  border-radius: 5px;
}


#pgCursoSite .info {
  white-space: pre-line;
  line-height: 27px;
}

#pgCursoSite .info h1
#pgCursoSite .info h2 {
  line-height: 40px;
}

#pgCursoSite .info p,
#pgCursoSite .info .description{
  border-top: #f1f1f1 solid 1px;
  margin-top: 15px;
  padding-top: 15px;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: #f1f1f1 solid 1px;
  /* text-align: justify; */
  font-size: 18px;
  line-height: 33px;
}

#pgCursoSite .price button {
  border: none;
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  font-size: 25px;
  background-color: #6dbc25;
  color: #fff;
  padding: 15px 25px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

#pgCursoSite .price {
  height: 355px;
}

#pgCursoSite .price h2 {
  margin-bottom: 10px;
}

#pgCursoSite .price p {
  font-size: 14px;
  margin-bottom: 15px;
}

#pgCursoSite .price p b,
#pgCursoSite .price p strong{
  font-size: 16px;
  font-weight: 500;
}
#pgCursoSite .price .info-2 {
  margin-bottom: 15px;
  border-bottom: #f1f1f1 solid 1px;
  padding-bottom: 15px;
  margin-top: 15px;
  border-top: #f1f1f1 solid 1px;
  padding-top: 15px;
  line-height: 27px;
}

.btn-ver-mapa {
  display: flex;
  gap: 5px;
  flex-direction: row;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  background-color: #688edb;
  color: #fff;
  padding: 5px 7px;
  border-radius: 25px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
}