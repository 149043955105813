#pgHomeSite {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#pgHomeSite .dvSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

#pgHomeSite .dvSearch span {
  display: flex;
  flex-direction: row;
}

#pgHomeSite .dvSearch span input::placeholder {
  color: #ccc;
  opacity: 1;
}

#pgHomeSite .dvSearch span input:-ms-input-placeholder {
  color: #ccc;
}

#pgHomeSite .dvSearch span input::-ms-input-placeholder {
  color: #ccc;
}

#pgHomeSite .dvSearch span input {
  min-width: 350px;
  background-color: #fff;
  border-radius: 5px 0px 0px 5px;
}

#pgHomeSite .dvSearch span button {
  cursor: pointer;
  background-color: #688edb;
  color: #fff;
  border: none;
  width: 50px;
  border-radius: 0px 5px 5px 0px;
}

#pgHomeSite .listCursos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-top: 35px;
}

#pgHomeSite .listCursos .item {
  width: 300px;
  max-height: 350px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px 15px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 15px 0px #dbdbdb;
  box-shadow: 0px 0px 15px 0px #dbdbdb;
}

#pgHomeSite .listCursos .item .imgContent {
  width: 270px;
  height: 170px;
}

#pgHomeSite .listCursos .item .imgContent img {
  width: 270px;
  height: 170px;
  object-fit: cover;
  background-color: #e3e3e3;
}

#pgHomeSite .listCursos .item .title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #363636;
}

#pgHomeSite .listCursos .item .description {
  flex: 1;
  max-height: 50px;
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 23px;
  white-space: pre-line;
  overflow: hidden;
}

#pgHomeSite .listCursos .item .footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

#pgHomeSite .listCursos .item .footer .price {
  font-size: 16px;
  font-weight: 500;
  color: #464646;
}

#pgHomeSite .listCursos .item .footer .btn {
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  font-size: 13px;
  background-color: #6dbc25;
  color: #fff;
  padding: 7px 15px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

#pgHomeSite .pagination {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

#pgHomeSite .pagination a {
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: #8788b0;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 100%;
}

#pgHomeSite .pagination p {
  display: flex;
  width: 12px;
  height: 25px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #8788b0;
}

#pgHomeSite .pagination a:hover {
  background-color: #ddd;
}

#pgHomeSite .pagination .selected {
  display: flex;
  color: #fff !important;
  background-color: #688edb !important;
  justify-content: center;
  align-items: center;
}