#pgEmpresa {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#pgEmpresa .form .title {
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  border-bottom: 1.5px solid #aaa;
  padding: 0px 10px;
  border-radius: 5px;
}

#pgEmpresa .form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

#pgEmpresa .btnPainel {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 0 auto;
}

#pgEmpresa .form label {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}