#pgCadCliente {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#pgCadCliente .painel {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#pgCadCliente .painel div label {
  padding: 0px 9px;
  font-weight: 500;
}

#pgCadCliente .painel .flexRow {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 425px) {
  #pgCadCliente .painel .flexRow {
    flex-direction: column;
  }

  #pgCadCliente .painel .flexRow div {
    width: 100%;
  }
}

@media screen and (min-width: 426px) {
  #pgCadCliente .painel .flexRow {
    flex-direction: row;
  }

  #pgCadCliente .painel .flexRow div {
    width: 50%;
  }
}

#pgCadCliente button {
  margin: 0 auto;
  padding: 8px 25px;
  border-radius: 25px;
  border: none;
  background-color: #6dbc25;
  color: #FFF;
  cursor: pointer;
}