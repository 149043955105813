#LayoutDashboard {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

#LayoutDashboard .menu {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 180px;
  background: #2d3744;
  padding: 5px 0px;
  align-items: center;
}

#LayoutDashboard .menu .logo {
  width: 130px;
  height: 130px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

#LayoutDashboard .menu nav {
  width: 100%;
  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
}

#LayoutDashboard .menu nav .navLink {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 7px;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  text-decoration: none;
  color: #8788b0;
  width: 100%;
  cursor: pointer;
  transition: all 0.5s;
}

#LayoutDashboard .menu nav .navLink p {
  margin-top: 2px;
}

#LayoutDashboard .menu nav .navLink:hover {
  background: #28313c;
}

#LayoutDashboard .menu nav .selected {
  background: #151b22;
  border-left: 3px solid #fff;
  color: #fff;
  font-weight: 500;
}

#LayoutDashboard .menu nav .selected p {
  color: #fff;
}

#LayoutDashboard .body {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
}

#LayoutDashboard .body .faixaTopo {
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background: #FFF;
  width: 100%;
  height: 70px;
}

#LayoutDashboard .body .faixaTopo .avatarIcon {
  font-size: 38px;
  margin: 0px 8px;
}

#LayoutDashboard .body .faixaTopo h3 svg {
  margin-right: 5px;
}

#LayoutDashboard .body .faixaTopo h3 {
  flex: 1;
}

#LayoutDashboard .body .faixaTopo .avatar {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

#LayoutDashboard .body .faixaTopo .avatar svg {
  cursor: pointer;
  margin-left: 5px;
  transition: all 0.5s;
}

#LayoutDashboard .body .faixaTopo .avatar svg:hover {
  cursor: pointer;
  margin-left: 5px;
  font-size: 18px;
}

#LayoutDashboard .body .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  -webkit-box-shadow: inset 0px 0px 15px 0px #c0c0c0;
  box-shadow: inset 0px 0px 15px 0px #c0c0c0;
}