 .btn-qtd-container {
   display: flex;
   flex-direction: row;
   border-width: 1px;
   border-style: solid;
   justify-content: space-between;
   align-items: center;
   border-radius: 25px;
   overflow: hidden;
   border-color: #6dbc25;
 }

 .btn-qtd-container span {
   font-size: 16px;
   margin: 0px 10px;
 }

 .btn-qtd-container button {
   background-color: #6dbc25;
   border: 0px;
   height: 30px;
   width: 30px;
   cursor: pointer;
 }

 .btn-qtd-container button svg {
   width: 15px !important;
 }

 .btn-qtd-container .icons {
   padding: 8px;
   width: 31px;
   height: 31px;
   color: white;
 }