#pagination {
  background-color: #fff;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 8px;
  width: 120px;
  -webkit-box-shadow: 0px 0px 15px 0px #eee;
  box-shadow: 0px 0px 15px 0px #eee;
}

#pagination p {
  margin: 0px 8px;
  font-size: 15px;
  font-weight: 500;
}