.painel-2{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.painel-2 > ul {
  padding: 16px 12px;
  border-radius: 5px;
  list-style-type: none;
  -webkit-box-shadow: 0px 0px 15px 0px #c0c0c0;
  box-shadow: 0px 0px 15px 0px #c0c0c0;
  background-color: #fff;
}

.painel-2 > ul > li ,
.painel-2 > div > ul > li {
  padding: 6px 12px;
  border-bottom: solid 1px #dedede; 
}
.painel-2 > ul > li:nth-last-child(1),
.painel-2 > div > ul > li:nth-last-child(1) {
  border: none;
}

.painel-2 > ul > li > a {
  text-decoration: none;
  color: inherit;
}
 
.painel-2 > ul > li:nth-child(1),
.painel-2 > div > ul > li:nth-child(1) { 
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: solid 1px #dedede;
  margin-bottom: 16px;
}

.painel-2 > ul:nth-child(1) {
  flex-basis: calc(430px - 16px);
  margin-right: 16px;
}

.painel-2 > div {
  flex: 1 1 100%;
}

.painel-2 > div > ul {
  padding: 16px 12px;
  -webkit-box-shadow: 0px 0px 15px 0px #c0c0c0;
  box-shadow: 0px 0px 15px 0px #c0c0c0;
  background-color: #fff;
}

.painel-2 > div > ul > li  {
  display: flex;
}

.painel-2 > div > ul > li:nth-child(2n+2){
  background-color: #f9f9f9;
}
.painel-2 > div > ul > li:hover {
  background-color: #f0f0f0;
}
.painel-2 > div > ul > li:nth-child(1):hover {
  background-color: transparent;
}

.painel-2 > div > ul > li + li {
  border-bottom: solid 1px #dedede; 
}

.painel-2 > div > ul > li:nth-last-child(1) {
  border: none;
}