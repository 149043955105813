#pgCarrinho {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#pgCarrinho .painel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
  gap: 10px;
}

#pgCarrinho .painel .removeItem {
  color: #b50000;
  margin-right: 10px;
  cursor: pointer;
}

@media screen and (max-width: 525px) {
  #pgCarrinho .painel {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (min-width: 525px) {
  #pgCarrinho .painel {
    flex-direction: row;
  }
}

#pgCarrinho .painel .nomeCurso {
  flex: 1;
}

#pgCarrinho .painel .valorCurso {
  display: flex;
  flex-direction: column;
  width: 110px;
  text-align: right;
  font-size: 18px;
  font-weight: 500;
}

#pgCarrinho .painel .valorCurso small {
  font-size: 12px;
  font-weight: 400;
}

#pgCarrinho .btnFinalizar {
  border: none;
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  font-size: 18px;
  background-color: #6dbc25;
  color: #fff;
  padding: 8px 25px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  margin-top: 15px;
}

#pgCarrinho .cartEmpty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#pgCarrinho .cartEmpty svg {
  margin: 15px;
  font-size: 70px;
  color: #ccc;
}

#pgCarrinho .cartEmpty p {
  font-size: 40px;
  color: #ccc;
  font-weight: 500;
}

#pgCarrinho .dvBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}