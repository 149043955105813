#mdlCursoImages {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#mdlCursoImages .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #18182c;
  color: #FFF;
  font-size: 22px;
  font-weight: 550;
  gap: 10px;
}

#mdlCursoImages .body {
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  align-items: center;
}

#mdlCursoImages .dropFile {
  width: 100%;
  height: 120px;
  border: dashed 2px #8788b0;
}

#mdlCursoImages .dropFile .fVfcRr>span {
  color: #8788b0;
}

#mdlCursoImages .dropFile svg {
  display: none;
}

#mdlCursoImages .body .imagesContent {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

#mdlCursoImages .body .imagesContent .imgCard {
  width: 100px;
}

#mdlCursoImages .body .imagesContent .imgCard img {
  width: 100px;
  height: 100px;
}

#mdlCursoImages .body .imagesContent .imgCard .footCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #18182c;
  padding: 7px;
  color: #fff;
  font-size: 14px;
  gap: 10px;
}

#mdlCursoImages .body .imagesContent .imgCard .footCard svg {
  cursor: pointer;
}

#mdlCursoImages .body .imagesContent .imgCard .footCard svg:nth-child(1) {
  font-size: 18px;
}